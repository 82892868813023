/*Стилизация формы*/
/* for timepicker */
.container_timepicker * {font-size: 10px;}
form { padding: 0; margin: 0; }
.container_timepicker { border: 1px solid #113740; background: #fff; color: #333; padding:2px; display:none; position:absolute; z-index:1300; }
.container_timepicker * { text-align: center!important; }
.container_timepicker table { border:0; border-spacing:2px; text-align:center; font-family: Arial,sans-serif; font-size: 11px; font-weight:bold; }
.cell_header_timepicker a { display: block; text-decoration: none; cursor: default; background: #113740; color: #fff; text-transform: uppercase; line-height: 19px; border: 1px solid #e6e6e6;}
.cell_timepicker { color: #004276; padding: 0; }
.cell_timepicker a, .disabled_timepicker a { background: #f2f2f2; border: 1px solid #e7e7e7; width: 40px; text-decoration: none; color: #000!important; display: block; line-height: 19px; }
.cell_timepicker a:hover { border: 1px solid #e7e7e7; background: #f2f2f2; color: #113740 !important; }
.cell_spaces { margin: 0 3px 0 0; }
.selected_timepicker a,.selected_timepicker a:hover { border: 1px solid #e6e6e6; background: #113740; font-weight: bold; color: #fff!important; }


.disabled_timepicker { padding:0; }
.disabled_timepicker a { opacity: .35; -moz-opacity: .35; -webkit-opacity: .35; filter: Alpha(Opacity=35); background-image: none; }