.fw_win_wrapper .fw_win_content_container {
	&.wns {
		&.adm-s {
			overflow-y: scroll;
			.fw_win_close_button {
				font-size: 20px;
				line-height: 50px;
			}
		}
		input, label, .input-wrapper {
			font-size: 12px;
		}
		border-radius: 3px;
		font-family: 'Open Sans' ,Arial, SansSerif;
		padding: 45px 70px 20px;
		.fw_win_title {
			margin: 6px 0 0;
			height: auto;
			text-align: center;
			font-size: 20px;
			.fw_win_close_button {
				background: none;
				width: auto;
				height: auto;
				right: 10px;
				&:before {
					content: "\f10c";
					display: inline-block;
					font-family: "Flaticon";
					font-style: normal;
					font-weight: normal;
					font-variant: normal;
					line-height: 1;
					text-decoration: inherit;
					text-rendering: optimizeLegibility;
					text-transform: none;
					-moz-osx-font-smoothing: grayscale;
					-webkit-font-smoothing: antialiased;
					font-smoothing: antialiased;
				}
			}
		}
		.fw_win_content {
			.input-wrapper {
				width: 100%;
				position: relative;
				label{
					font-weight: bold;
					color: #4e678f;
				}
				&.col-50 {
					width: 50% !important;
					float: left;
				}
				&.link {
					> input {
						padding-right: 60px;
					}
					.-link {
						position: absolute;
						right: 0;
						top: 0;
						background: #4e678f;
						color: #fff;
						border-top-right-radius: 19px;
						border-bottom-right-radius: 19px;
						padding: 2px 18px 6px 12px;
						font-size: 18px;
						&:hover {
							background: #3f6197;
						}
					}
				}
				input + label, textarea + label {
					position: absolute;
					top: 7px;
					font-weight: normal;
					left: 15px;
					color: #999;
					transition: 0.2s;
				}
				input, textarea {
					&:focus + label, &:valid + label {
						top: -18px;
						left: 0;
						font-weight: bold;
						color: #4e678f;
						&:after {
							content: ':';
						}
					}
				}

				textarea {
					height: 75px;
					padding-top: 10px;
				}
				.image_extended_wrapper {
					height: 60px;
					.ext_images_preview_block {
						border: 1px solid #e6ebf1;
						padding: 0;
						height: 60px;
						border-radius: 20px;
						width: 85px;
						background-size: cover;
					}
					.ext_images_description_block.with_image {
						height: 60px;
						padding: 0 0 0 10px;
						> .multi_group {
							padding: 0;
							color: #4e678f;
						}
						&:hover {
							background-color: transparent !important;
						}
					}
					.ext_images_clear_block {
						height: auto;
						width: auto;
						position: absolute;
						right: 10px;
						top: 15px;
						background: 0 0;
						text-decoration: none;
						font-size: 20px;
						color: #4e678f;
						transition: color 0.5s;
						&:before {
							content: "\f10c";
							display: inline-block;
							font-family: Flaticon;
							font-weight: 400;
							line-height: 1;
							text-decoration: inherit;
							-webkit-font-smoothing: antialiased;
							font-smoothing: antialiased;
						}
						&:hover {
							background-color: transparent !important;
							color: #e6ebf1;
						}
					}
					.ext_images_description_block.no_image {
						width: 100%;
						padding: 0;
						height: 60px;
						border: 2px dashed #e6ebf1;
						border-radius: 5px;
						text-align: center;
						.ext_img_description_single_line {
							line-height: 60px;
						}
					}
				}
			}
			.check-wrapper {
				*[type="checkbox"] {
					display: none;
				}
				*[type="checkbox"] + label {
					padding-left: 24px;
					padding-top: 2px;
					font-size: 13px;
					pointer-events: auto;
					cursor: default;
					color: #4e678f;
					position: relative;
					display: block;
					line-height: 18px;
					user-select: none;
				}
				*[type="checkbox"] + label:before {
					display: inline-block;
					content: '';
					width: 13px;
					height: 13px;
					background-color: #fff;
					border: 1px solid #e6ebf1;
					position: absolute;
					left: 0;
					top: 4px;
					border-radius: 4px;
				}
				*[type="checkbox"]:checked + label:before {
					background: #4e678f url('/images/admin/admin_icons/dashboard/cheked_white.png') no-repeat center;
					border-color: #4e678f;
				}
			}
			.submit_block{
				text-align: center;
				.submit_button {
					display: inline-block;
					padding: 10px 35px;
					background: #4e678f !important;
					color: #fff;
					height: auto;
					border: none;
					font-size: 12px;
					border-radius: 20px;
					margin-top: 17px;
					text-decoration: none;
				}
			}
		}
	}
}