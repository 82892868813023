.footer{
	height: 320px;
	background: #F4F4F4;
	padding-top: 65px;
	padding-bottom: 40px;
	.height{
		height: 100%;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
	}
	.left-block{
		height: 100%;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-pack: justify;
		-ms-flex-pack: justify;
		justify-content: space-between;
		margin-right: 85px;
		.phone-wrapper{
			.item{
				&.-margin{
					margin-top: -7px;
				}
				.phone{
					font-size: 32px;
					color: #000;
					font-family: 'pragmatica-cor', sans-serif;
					.orange{
						color: $orange;
					}
				}
				.description{
					font-size: 17px;
					font-family: 'pragmatica-cor', sans-serif;
				}
			}
		}
		.address{
			font-size: 15px;
			font-weight: 300;
			max-width: 230px;
		}
	}
	.right-block{
		max-width: 920px;
		.logo-wrapper{
			width: 195px;
			height: 43px;
			margin-bottom: 25px;
			.photo{
				width: 100%;
			}
		}
		.description-wrapper{
			.text{
				font-weight: 300;
				font-size: 15px;
				&:nth-child(1){
					margin-bottom: 20px;
				}
			}
		}
	}
}