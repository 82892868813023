@keyframes rotate {
	100% {
		-moz-transform: rotate(360deg);
		-o-transform: rotate(360deg);
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}
/*fgfgdfhdfg*/
@keyframes dash {
	0% {
		stroke-dasharray: 1, 200;
		stroke-dashoffset: 0;
	}
	50% {
		stroke-dasharray: 89, 200;
		stroke-dashoffset: -35px;
	}
	100% {
		stroke-dasharray: 89, 200;
		stroke-dashoffset: -124px;
	}
}

@keyframes color {
	100%, 0% {
		stroke: #0093e7;
	}
}

.mojax_block_overlay_content {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	overflow: hidden;
}

.mojax_block_overlay_content .text {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	margin: auto;
	vertical-align: middle;
	text-align: center;
	font-size: 13px;
	font-weight: 900;
	font-family: Arial, sans-serif;
	color: #a5a5a5;
	z-index: 11;
}

.mojax_block_overlay_content .circular .path {
	stroke-dasharray: 1, 200;
	stroke-dashoffset: 0;
	animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
	stroke-linecap: round;
}

.mojax_block_overlay_content .circular {
	animation: rotate 2s linear infinite;
	transform-origin: center center;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	margin: auto;
	z-index: 11;
}

.mojax_block_overlay_content .circular.ie {
	border-radius: 100%;
	border: 3px solid #0093e7;
	border-right: 3px solid transparent;
}
