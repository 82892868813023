@font-face {
	font-family: 'pragmatica';
	src: url('/files/fonts/pragmatica/pragmatica.ttf') format('truetype');
	font-weight: 400;
}

@font-face {
	font-family: 'pragmatica';
	src: url('/files/fonts/pragmatica/pragmatica-bold.ttf') format('truetype');
	font-weight: 600;
}

@font-face {
	font-family: 'pragmatica';
	src: url('/files/fonts/pragmatica/pragmaticalightc-bold.otf') format('opentype');
	font-weight: 700;
}

@font-face {
	font-family: 'pragmatica';
	src: url('/files/fonts/pragmatica/pragmaticalightc.otf') format('opentype');
	font-weight: 300;
}

@font-face {
	font-family: 'pragmatica-cyrillic';
	src: url('/files/fonts/pragmatica/pragmatica-cyrillic.ttf') format('truetype');
	font-weight: 400;
}

@font-face {
	font-family: 'pragmatica-cyrillic';
	src: url('/files/fonts/pragmatica/pragmatica-bold-cyrillic.ttf') format('truetype');
	font-weight: 600;
}

@font-face {
	font-family: 'pragmatica-cor';
	src: url('/files/fonts/pragmatica/PragmaticaC.woff') format('woff');
}
