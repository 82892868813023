.block-in-taxi {
	height: 450px;
	width: 100%;
	background: url("/files/image/banners/Granta-Sedan-Motive.png") no-repeat;
	background-size: cover;
	background-position-y: 60%;
	.text-container {
		font-family: 'pragmatica', sans-serif;
		height: 100%;
		width: 50%;
		margin-left: auto;
		position: relative;
		z-index: 1;
		padding-left: 70px;
		padding-top: 85px;
		&::after {
			content: "";
			width: 100%;
			height: 100%;
			position: absolute;
			background: #7D675A;
			opacity: .82;
			left: 0;
			top: 0;
			z-index: -1;
		}
		.title {
			font-weight: 700;
			color: #fff;
			font-size: 30px;
			margin-bottom: 3px;
		}
		.description-wrapper {
			max-width: 535px;
			.text {
				font-weight: 300;
				color: #fff;
				font-size: 17px;
				margin-bottom: 15px;
			}
		}
	}
}