@mixin transformCss($property)
{
	-moz-transform:    $property;
	-o-transform:      $property;
	-webkit-transform: $property;
	transform:         $property;
}

@mixin transitionCss($property)
{
	-moz-transition:    $property;
	-o-transition:      $property;
	-webkit-transition: $property;
	transition:         $property;
}