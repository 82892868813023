.participants {
	padding-top: 85px;
	padding-bottom: 90px;
	.main-title {
		color: #F56920;
		font-weight: 300;
		font-size: 40px;
		margin-bottom: 10px;
	}
	.sub-title {
		font-size: 25px;
		font-family: 'pragmatica-cyrillic', sans-serif;
		font-weight: 400;
	}
	.list {
		list-style-type: none;
		padding-left: 0;
		margin-top: 12px;
		.item {
			font-weight: 300;
			font-size: 17px;
			margin-bottom: 5px;
			position: relative;
			padding-left: 20px;
			&::after {
				content: "";
				position: absolute;
				width: 11px;
				height: 11px;
				left: 0;
				top: 5px;
				background: $orange;
				border-radius: 2px;
			}
		}
	}
	.icon-with-text-wrapper{
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		margin-top: 30px;
		margin-bottom: 28px;
		.icon-with-text{
			display: -webkit-box;
			display: -ms-flexbox;
			display: flex;
			-webkit-box-align: center;
			-ms-flex-align: center;
			align-items: center;
			&:nth-child(1){
				margin-right: 120px;
			}
			.text{
				font-size: 18px;
				font-weight: 300;
			}
			.icon-wrapper{
				margin-right: 20px;
				.icon{
					fill: $orange;
					&.icon-case{
						width: 50px;
						height: 40px;
					}
					&.icon-agreement{
						width: 29px;
						height: 40px;
					}
				}
			}
		}
	}
}