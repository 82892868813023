.head {
	height: 117px;
	padding: 30px 0;
	.logo-wrapper {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		width: -webkit-fit-content;
		width: -moz-fit-content;
		width: fit-content;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		.item {
			&.major-logo {
				margin-right: 40px;
			}
			&.lada-logo {
				height: 31px;
				width: 142px;
				.photo {
					width: 100%;
					height: 100%;
				}
			}
		}
	}
	.content-wrapper {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		width: -webkit-fit-content;
		width: -moz-fit-content;
		width: fit-content;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		margin-left: auto;
		.phone-wrapper {
			.item {
				&.-margin {
					margin-top: -7px;
				}
				.phone {
					font-size: 32px;
					color: #000;
					font-family: 'pragmatica-cor', sans-serif;
					.orange {
						color: $orange;
					}
				}
				.description {
					font-size: 17px;
					font-family: 'pragmatica-cor', sans-serif;
				}
			}
		}
		.button-wrapper {
			margin-left: 35px;
		}
	}
	.flex {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
	}
}