.card-block {
	font-family: 'pragmatica-cyrillic', sans-serif;
	.card {
		border-bottom: 1px solid #E8E8E8;
		.info {
			display: -webkit-box;
			display: -ms-flexbox;
			display: flex;
			-webkit-box-align: center;
			-ms-flex-align: center;
			align-items: center;
			padding-bottom: 45px;
			padding-top: 40px;
		}
		.photo-wrapper {
			margin-right: 110px;
		}
		.description-wrapper {
			.main-title {
				font-family: 'pragmatica', sans-serif;
				font-weight: 700;
				color: #666666;
				font-size: 40px;
				margin-bottom: 5px;
			}
			.line {
				display: -webkit-box;
				display: -ms-flexbox;
				display: flex;
				.cost, .advance, .payment {
					.title {
						font-size: 20px;
						font-weight: 600;
						color: #535A5D;
					}
					.value {
						color: $orange;
						font-size: 25px;
						font-weight: 400;
					}
				}
				.cost, .advance {
					margin-right: 60px;
				}
			}
			.more {
				width: -webkit-fit-content;
				width: -moz-fit-content;
				width: fit-content;
				margin-top: 25px;
				position: relative;
				display: inline-block;
				&:after {
					color: $orange;
					content: "\f109";
					display: inline-block;
					font-family: "Flaticon";
					font-style: normal;
					font-weight: normal;
					font-variant: normal;
					line-height: 1;
					text-decoration: inherit;
					text-rendering: optimizeLegibility;
					text-transform: none;
					-moz-osx-font-smoothing: grayscale;
					-webkit-font-smoothing: antialiased;
					font-smoothing: antialiased;
					position: absolute;
					top: 53%;
					@include transformCss(translateY(-50%));
					right: -15px;
					font-size: 12px;
				}
				.link {
					font-family: 'pragmatica', sans-serif;
					font-weight: 700;
					font-size: 15px;
					color: #666666;
					text-decoration: underline;
					&:hover {
						text-decoration: underline;
					}
				}
			}
		}
	}
}