.submit-lease {
	background: #F9F6F6;
	padding-top: 65px;
	padding-bottom: 70px;
	.main-title {
		color: $orange;
		font-weight: 300;
		font-size: 40px;
		margin-bottom: 7px;
	}
	.description {
		font-weight: 300;
		font-size: 20px;
		.link{
			color: #000;
		}
	}
	.line {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: end;
		-ms-flex-align: end;
		align-items: flex-end;
		margin-top: 45px;
		.item-group {
			width: 291px;
			margin-right: 30px;
			label {
				font-family: 'pragmatica', sans-serif;
				font-weight: 400;
				font-size: 13px;
				padding-left: 15px;
			}
		}
	}
	.application {
		font-weight: 300;
		font-size: 15px;
		margin-top: 5px;
		.link {
			color: $orange;
			text-decoration: underline;
			&:hover {
				text-decoration: underline;
			}
		}
	}
	.form_success_answer_wrapper{
		position: relative;
		top: 0;
		@include transformCss(translateY(0));
		h3{
			color: #F56920;
			font-weight: 300;
			font-size: 40px;
			margin-bottom: 15px;
		}
		p{
			font-weight: 300;
			font-size: 20px;
		}
	}
}