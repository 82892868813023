/* fw_win Windows 
 * Стилизация кастомных новых окон
 **********************************************************/
.fw_win_overlay_wrapper{
	overflow: auto;
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	background: url(/images/admin/fw_win/overlay_filler.png) repeat 0 0 transparent;
}
.fw_win_wrapper{
	width: 100%;
	position: absolute;
	overflow: auto;
	text-align: center;
	white-space: nowrap;
	-webkit-overflow-scrolling: touch;
}
.fw_win_common_offset{
	padding: 15px;
}
.fw_win_wrapper .fw_win_content_container{
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	-ms-border-radius: 5px;
	border-radius: 5px;
	position:relative;
	background: #fff;
	display: inline-block;
	vertical-align: middle;
	white-space: normal;
	text-align: left;
	min-height: 100px;
//display: inline;
//zoom: 1;
}
.fw_win_wrapper .fw_win_content_container .fw_win_title{
	height: 40px;
	line-height: 40px;
	font-size: 20px;
	margin-left: 15px;
	position: relative;
}
.fw_win_wrapper .fw_win_content_container .fw_win_title .fw_win_close_button{
	position: absolute;
	height: 40px;
	width: 40px;
	cursor: pointer;
	top: 0;
	right: 0;
	background: url(/images/admin/fw_win/close_button.png) no-repeat center center;
	opacity: 0.5;
}
.fw_win_wrapper .fw_win_content_container .fw_win_title .fw_win_close_button:hover{
	opacity: 1;
}
.fw_win_wrapper .fw_win_content_block{
	margin: 0 auto;
}
.fw_win_wrapper .fw_win_height_fixer{
	display:inline-block;
	height: 100%;
	width: 0;
	vertical-align: middle;
//display: inline;
//zoom: 1;
}