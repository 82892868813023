.fluid-banner {

	* {
		outline: none;
	}
	.slick-arrow{
		font-size: 0;
		border: none;
		display: block;
		height: 43px;
		width: 30px;
		background: url('/files/image/banners/arrow.png') no-repeat;
		z-index: 5000;
		position: absolute;
		opacity: 0.6;
		&:hover{
			opacity: 1;
		}
		&.slick-prev{
			@include transformCss(rotate(180deg)  translateY(-50%));
			top: 42%;
			left: 5%;
		}
		&.slick-next{
			@include transformCss(translateY(-50%));
			top: 50%;
			right: 5%;
		}
	}

	.item {
		background-size: cover;
		position: relative;
		height: 587px;
		&:not(.slick-slide) {
			display: none;
			&:first-child {
				display: block;
				margin: {
				}
			}
		}
		.banner-content {
			height: 587px;

		}
		.on-banner-content {
			position: absolute;
			top: 0;
			width: 100%;
			height: 100%;
		}
		.content {
			line-height: 1;
			* {
				color: #fff;
			}
			.header {

			}
			.text {
				font-size: 18px;
				line-height: 25px;
				height: 55px;
				overflow: hidden;

			}
			.button {
				line-height: 47px;

				&:hover {
					text-decoration: none;
					background-color: rgba(255, 255, 255, .8) !important;
				}
			}
		}
		.image {
			background: {
				size: cover;
				position: 0 0;
				repeat: no-repeat;
			}
			height: 587px;
			background-position: center;
			&.d-md-none {
				display: none;
			}
		}
	}
	.slick-dots {
		display: none !important;
	}
	&.-no-dots {
		margin-bottom: 58px;
		.slick-dots {
			display: none !important;
		}
	}
}
.reviews-list {
	background: url("/images/content/quote.png") 90% 0 no-repeat;

	-webkit-border-radius: 10px;
	-moz-border-radius: 10px;
	border-radius: 10px;

	padding: 40px 30px 25px 30px;
	&.faq-page {
		&:after, &:before {
			content: none;
		}
	}
	.line-break {
		margin-bottom: 28px;
	}
	.slick-dots {
		text-align: right;
	}
	.review-slider {
		margin-bottom: 10px;
		.blind {
			background: #fff;
		}
		.item {
			&:not(.slick-slide) {
				display: none;
				&:first-child {
					display: block;
				}
			}
			img {
				display: inline-block;
			}
			.text {
				line-height: 27px;
			}
			.sign {
				margin-top: 25px;
				.name {
					font-weight: 600;
				}
				.tail {
					color: #888484;
				}
			}
		}
	}
	position: relative;
	&:after {
		content: '';
		border-style: solid;
		border-width: 0 17px 15px 0;
		border-color: transparent transparent transparent;
		position: absolute;
		left: calc(50% - 10px);
		bottom: -15px;
		z-index: 5;
	}
	&:before {
		content: '';
		border-style: solid;
		border-width: 0 15px 12.5px 0;
		border-color: transparent white transparent transparent;
		position: absolute;
		left: calc(50% - 9px);
		bottom: -12.5px;
		z-index: 10;
	}
	.button {
		display: inline-block;
		margin: {
			top: 3px;
		}
	}
}
.arrows {
	position: relative;
	z-index: 1000;
	.slick-arrow {
		position: absolute;
		font-size: 0;
		line-height: 0;
		width: 33px;
		height: 100px;
		border: none;
		background: transparent;
		outline: none;
		cursor: pointer;
		&:before {
			content: '';
			width: 33px;
			height: 100px;
			background: url("/images/content/arrow.png") 0 0 no-repeat transparent;
			position: absolute;
			color: #000;
			z-index: 1000;
			cursor: pointer;
			left: 0;
			top: 0;
		}
		&.slick-next {
			right: 0;
			-webkit-transform: rotate(180deg);
			-moz-transform: rotate(180deg);
			-ms-transform: rotate(180deg);
			-o-transform: rotate(180deg);
			transform: rotate(180deg);
			top: 10px;
		}
		&.slick-prev {
			left: 0;
			top: 10px;
		}
	}
}
.center-slider {
	.elements {
		height: 481px;
		overflow: hidden;
		opacity: 0;
		&.slick-initialized {
			opacity: 1;
		}
		.slick-track {
			.slick-slide {
				width: 723px;
				opacity: .3;
				overflow: hidden;

				&.slick-center {
					opacity: 1;
				}
				img {
					width: 100%;
				}
			}
		}

	}
	.slick-dots {
		line-height: 67px;
	}
}
.banner-wrapper{
	position: relative;
}