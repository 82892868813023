body{
	padding: 0;
	min-width: 1500px !important;
	overflow: auto;
	width: 100vw;
}
.open-form{

}
.container {
	min-width: 1340px !important;
}

body {
	font-family: 'pragmatica', sans-serif !important;
	color: #000 !important;
	min-width: 1500px !important;
	span {
		color: #000;
	}
}

a {
	color: #000;
	&:hover {
		text-decoration: none !important;
	}
}

input {
	width: 100%;
	border: none;
	border-radius: 4px;
	height: 45px;
	font-size: 16px;
	padding: 13px 18px 10px 18px;
	-webkit-box-shadow: 0 3px 10px 0 rgba(0, 0, 0, .16);
	-moz-box-shadow: 0 3px 10px 0 rgba(0, 0, 0, .16);
	box-shadow: 0 3px 10px 0 rgba(0, 0, 0, .16);
	font-weight: 400;
	outline: none;
	font-family: 'pragmatica-cor', sans-serif !important;
	@include transitionCss(all .3s ease-in-out);
}

.button {
	height: 45px;
	padding: 13px 53px 15px 53px;
	min-width: 235px;
	border-radius: 4px;
	background: $orange;
	font-size: 16.5px;
	color: #fff;
	text-align: center;
	cursor: pointer;
	outline: none;
	border: none;
	@include transitionCss(all .1s ease-in-out);
	font-family: 'pragmatica-cor', sans-serif;
	&:hover {
		@include transitionCss(all .1s ease-in-out);
		-webkit-box-shadow: 0 3px 10px 0 rgba(0, 0, 0, .16);
		-moz-box-shadow: 0 3px 10px 0 rgba(0, 0, 0, .16);
		box-shadow: 0 3px 10px 0 rgba(0, 0, 0, .16);
	}
}