#feedback_place,  #banner_feedback_place{
	width: 398px;
	height: 428px;
	margin: 0 auto;
	padding: 40px 40px 45px 40px;
	border-radius: 2px;
	z-index: 1;
	position: absolute !important;
	top: 18%;
	right: 12%;
	&:after {
		content: "";
		width: 100%;
		height: 100%;
		position: absolute;
		left: 0;
		top: 0;
		background: #FFFFFF;
		opacity: .82;
		z-index: -1;
	}
	form {
		height: 100%;
	}
}

.field-wrapper {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	font-family: 'pragmatica-cor', sans-serif !important;
	margin-bottom: 26px;
	.label-wrapper {
		label {
			font-size: 13.5px;
			font-weight: 400;
			padding-left: 18px;
			margin-bottom: 7px;
			.formjor-asterisk{
				display: none;
			}
		}
	}
	.input-form-wrapper {
		position: relative;
		&.formjor-err {
			.error_wrapper {
				display: block;
			}
		}
	}
	.error_wrapper {
		display: none;
		position: absolute;
		left: 0;
		bottom: -20px;
		.error_text {
			font-weight: 300;
			font-size: 13px;
			color: #ff0000;
			padding-left: 18px;
			padding-top: 4px;
		}
	}
	input[type=checkbox] {
		display: none;
	}
	input[type=checkbox] + label {
		padding-left: 33px;
		z-index: 10000;
		position: relative;
		font-size: 12.5px;
		color: #717171;
		font-weight: 400;
		&:before {
			content: '';
			display: inline-block;
			width: 20px;
			height: 20px;
			margin-right: 10px;
			position: absolute;
			left: 0;
			top: 1px;
			border: 2px solid $orange;
			border-radius: 4px;
		}
		&:after {
			font-family: "Flaticon";
			width: 8px;
			height: 8px;
			position: absolute;
			top: 2.8888px;
			left: 4px;
			font-size: 13px;
			content: "\f10b";
			color: transparent;
			display: block;
		}
		&.formjor-err {
			color: #ff0000;
			.agree_win {
				color: #ff0000;
			}
			&:before {
				border-color: #ff0000;
			}
		}
	}
	.checkbox-form-wrapper {
		margin-top: 5px;
		.agree_win {
			color: #717171;
			text-decoration: underline;
			&:hover {
				color: $orange;
			}
		}
	}
	input[type=checkbox]:checked + label {
		&:before {
			border-color: $orange;
		}
		&:after {
			color: $orange;
		}
	}
	.button {
		outline: none;
		border: none;
		margin-top: 15px;
	}
}

input:-webkit-autofill, textarea:-webkit-autofill, select:-webkit-autofill {
	background-color: rgb(255, 255, 255) !important;
	background-image: none !important;
	color: rgb(0, 0, 0) !important;
}

.fw_win_wrapper.agree_wrapper .fwin_body_scroll_fix .fw_win_content_block {
	.fw_win_content_container {
		padding: 15px 35px 20px !important;
		.fw_win_title {
			text-align: left;
			padding-left: 15px;
			font-size: 15px !important;
			margin-bottom: 5px !important;
			h1 {
				margin-bottom: 0 !important;
			}
			.fw_win_close_button {
				top: -23px;
				right: -15px;
			}
		}
	}
}

.horizontal-form {
	.form_inner_wrapper {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: end;
		-ms-flex-align: end;
		align-items: flex-end;
		margin-top: 45px;
		margin-left: 5px;
	}
	.field-wrapper {
		width: 291px;
		margin-right: 35px;
		&.checkbox {
			display: none;
		}
	}
}

.banner-form {
	.answer_block_button {
		display: none;
	}
}

.form_success_answer_wrapper {
	position: absolute;
	top: 50%;
	@include transformCss(translateY(-50%));
	h3 {
		font-family: 'pragmatica', sans-serif;
		font-weight: 300;
		color: #F56920;
		text-align: left;
		font-size: 30px;
		margin-bottom: 20px;
	}
	p {
		font-weight: 300;
		font-size: 17px;

	}
}
.fw_win_common_offset{
	height: 100%;
	form{
		height: 100%;
		.form_success_answer_wrapper{
			top: 40%;
		}
	}
}
.fw_win_overlay_wrapper , .fw_win_wrapper{
	width: 100vw;
	min-width: 1500px !important;
}
.fw_win_wrapper .fwin_body_scroll_fix .fw_win_content_block {
	.fw_win_content_container {
		.fw_win_title {
			font-family: 'pragmatica', sans-serif;
			font-weight: 300;
			color: $orange;
			font-size: 40px !important;
			margin-bottom: 0 !important;
			.fw_win_close_button {
				opacity: 1;
				top: -45px;
				right: -50px;
				&:before {
					content: "\f10b";
					color: #000;
					font-size: 20px;
				}
			}
		}
	}
	.field-wrapper .label-wrapper label {
		font-size: 13px;
	}
}