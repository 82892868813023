#system_debug_bar .system_debug_message p{
	margin: 0;
	padding: 0;
}
#system_debug_bar .system_debug_message b{
	font-weight: bold;
}
.object_wrapper,
#system_debug_bar .system_debug_message .object_wrapper{
	padding-left: 25px;
	border-left: 1px dotted #84afff;
}
.object_wrapper .object_key,
#system_debug_bar .system_debug_message .object_key{
	color: #0040b6;
	font-weight: bold;
}
.value_is_object,
#system_debug_bar .system_debug_message .value_is_object{
	color: blue;
}
.value_is_bool,
.value_is_null,
#system_debug_bar .system_debug_message .value_is_bool,
#system_debug_bar .system_debug_message .value_is_null{
	color: #0097ff;
}
.value_is_string,
#system_debug_bar .system_debug_message .value_is_string{
	color: #080229;
}
.primitive_value,
#system_debug_bar .system_debug_message .primitive_value{
	font-weight: bold;
}
.debug_source,
#system_debug_bar .system_debug_message .debug_source{
	float: right;
}
.function_name,
#system_debug_bar .system_debug_message .function_name{
	color: #028102;
}
.function_params,
#system_debug_bar .system_debug_message .function_params{
	color: #B13902;
}
#system_debug_bar .system_debug_title{
	font-size:16px;
	background-color:#fcffc6;
}